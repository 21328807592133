<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="updateSeries()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="general-balance-all-graph-container">
      <div class="information-cards-container" v-if="bankAccounts.length > 0">
        <InformationCard
          name="Saldo total en todas las cuentas bancarias"
          :value="totalBalance"
          :max="finalBalance"
          format="money"
          width="half"
        />

        <InformationCard
          name="Saldo consolidado total en todas las cuentas bancarias"
          :value="totalBalanceCon"
          :max="finalBalance"
          format="money"
          width="half"
        />
      </div>
      <div
        class="general-balance-graph-container global-border-radius global-border-shadow general-balance-graph-container-left"
        v-if="bankAccounts.length > 0"
      >
        <apexchart
          width="95%"
          type="donut"
          :options="accountsChart.options"
          :series="accountsChart.series"
        ></apexchart>
      </div>
      <div
        class="general-balance-graph-container global-border-radius global-border-shadow general-balance-graph-container-right"
        v-if="bankAccounts.length > 0"
      >
        <apexchart
          width="95%"
          type="donut"
          :options="accountsConsolidatedChart.options"
          :series="accountsConsolidatedChart.series"
        ></apexchart>
      </div>
      <div
        class="message-full-width global-border-radius global-border-shadow"
        v-if="bankAccounts.length === 0"
      >
        <p>No hay cuentas bancarias en el sistema</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InformationCard from "../../Global/components/InformationCard";
// @ is an alias to /src
export default {
  name: "GeneralResumee",
  components: {
    InformationCard,
  },
  data() {
    return {
      accountsChart: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Saldo en cuentas bancarias",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",
                    fontSize: "1.4rem",
                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [],
      },
      accountsConsolidatedChart: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Saldo consolidado en cuentas bancarias",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",
                    fontSize: "1.4rem",
                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [],
      },
      refreshLoading: false,
    };
  },
  methods: {
    updateSeries() {
      this.refreshLoading = true;

      let accountsLabels = [];
      let accountsSeries = [];
      let consolidatedLabels = [];
      let consolidatedSeries = [];
      // Move through all the bank accounts and add the name as the label and the current balance to the series
      for (const singleAccount of this.bankAccounts) {
        accountsLabels.push(singleAccount.NOMBRE);
        accountsSeries.push(singleAccount.SALDO_ACTUAL.SALDO_ACTUAL);
        consolidatedLabels.push(singleAccount.NOMBRE);
        consolidatedSeries.push(singleAccount.SALDO_CON_ACTUAL.SALDO_ACTUAL);
      }
      // Set the char options to the ones we got from the bank accounts
      this.accountsChart.options = {
        ...this.accountsChart.options,
        labels: accountsLabels,
      };
      this.accountsConsolidatedChart.options = {
        ...this.accountsConsolidatedChart.options,
        labels: consolidatedLabels,
      };
      // Set the series to the ones we got from the bank accounts
      this.accountsChart.series = accountsSeries;
      this.accountsConsolidatedChart.series = consolidatedSeries;

      this.refreshLoading = false;
    },
  },
  created() {
    moment.locale("es");
    this.updateSeries();
  },
  mounted() {
    this.updateSeries();
  },
  computed: {
    // The current bank accounts in the store
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS.map((singleBankAccount) => {
        let singleBankAccountWithProps = { ...singleBankAccount };
        return singleBankAccountWithProps;
      });
    },
    // The total balance from all bank accounts
    totalBalance() {
      return this.$store.getters.TOTALBALANCE;
    },
    // The total consolidated balance from all bank accounts
    totalBalanceCon() {
      return this.$store.getters.TOTALBALANCECON;
    },
    finalBalance() {
      return this.totalBalance;
    },
  },
  watch: {
    // When bank accounts change, update the graph
    bankAccounts() {
      this.updateSeries();
    },
  },
};
</script>

<style scoped>
@import "../style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
</style>
