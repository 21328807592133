/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Sincronizar movimientos bancarios
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado</span>. En algunos bancos
      solo puede buscarse información en periodos de 6 meses o menos
    </div>

    <div id="belvo"></div>

    <div class="manual-bank-account">
      <h3 class="form-division">Información general</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Fecha inicial para búsqueda (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          :type="{
            'is-danger': newBankAccountMovementSynchRequiredErrors.FECHA_INICIO,
          }"
          :message="{
            'La fecha de inicio no es valida ':
              newBankAccountMovementSynchRequiredErrors.FECHA_INICIO,
          }"
        >
          <b-datepicker
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            v-model="newBankAccountMovementSynch.FECHA_INICIO"
            required
            class="required-input"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Ejemplo 20/10/2018"
            trap-focus
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha final para búsqueda  (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          :type="{
            'is-danger': newBankAccountMovementSynchRequiredErrors.FECHA_FIN,
          }"
          :message="{
            'La fecha final no es valida ':
              newBankAccountMovementSynchRequiredErrors.FECHA_FIN,
          }"
        >
          <b-datepicker
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            v-model="newBankAccountMovementSynch.FECHA_FIN"
            required
            class="required-input"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Ejemplo 20/10/2018"
            trap-focus
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datepicker>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="connectMovements()"
        >Sincronizar movimiento bancario</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import ConnectBankAccountModal from "./ConnectBankAccountModal";

export default {
  name: "ConnectBankMovementsModal",
  props: ["bankAccount"],
  components: {},
  data() {
    return {
      moment: moment,
      newBankAccountMovementSynch: {
        FECHA_INICIO: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
        ),
        FECHA_FIN: new Date(),
      },
      newBankAccountMovementSynchRequiredErrors: {
        FECHA_INICIO: false,
        FECHA_FIN: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async connectMovements() {
      // Update invalid link if the account needs it
      if (this.bankAccount.ACTUALIZAR_LINK) {
        this.closeModal();
        this.updateLink(this.bankAccount.LINK);
        return;
      }

      try {
        this.newBankAccountMovementSynchRequiredErrors = validateFields(
          this.newBankAccountMovementSynch,
          this.newBankAccountMovementSynchRequiredErrors
        );
        if (
          checkValidationErrors(this.newBankAccountMovementSynchRequiredErrors)
        ) {
          let response = await this.$store.dispatch(
            "SYNCHBANKACCOUNTMOVEMENTS",
            {
              bankAccount: this.bankAccount._id,
              bankAccountMovementSynch: this.newBankAccountMovementSynch,
            }
          );
          if (response == "Success") {
            this.deleteInformation();
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message:
                "Hemos recibido con éxito la información!! Seguimos trabajando para sincronizarla, se te notificará una vez terminado el proceso. Este proceso por lo regular tarda un par de minutos, mientras tanto puedes seguir disfrutando de tu experiencia! 😃",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al sincronizar los movimientos de la cuenta bancaria en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al sincronizar los movimientos de la cuenta bancaria en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al sincronizar los movimientos de la cuenta bancaria en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newBankAccountMovementSynch = {
        FECHA_INICIO: new Date(),
        FECHA_FIN: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
        ),
      };
    },
    updateLink(bankAccountLink) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ConnectBankAccountModal,
        props: {
          linkId: bankAccountLink,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
