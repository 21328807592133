var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Agregar movimientos bancarios")]),_vm._m(0),_c('div',{attrs:{"id":"belvo"}}),_c('div',{staticClass:"manual-bank-account"},[_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Monto(Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newBankAccountMovementRequiredErrors.IMPORTE },"message":{
          'El importe no es valido':
            _vm.newBankAccountMovementRequiredErrors.IMPORTE,
        }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":2,"placeholder":"Ejemplo: 123","icon":"cash-100","required":true,"disabled":false},model:{value:(_vm.newBankAccountMovement.IMPORTE),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "IMPORTE", $$v)},expression:"newBankAccountMovement.IMPORTE"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Proveedor (Opcional)","expanded":"","label-position":"inside","required":""}},[_c('b-autocomplete',{attrs:{"placeholder":"Proveedor","data":_vm.autoCompleteProviders(_vm.provName),"field":"NOMBRE_PRV","autocomplete":"","icon":"credit-card-outline","open-on-focus":"","expanded":"","clearable":"","check-infinite-scroll":""},on:{"select":function (option) {
              _vm.newBankAccountMovement.CLAVE_PRV = option._id;
            }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("No se encontraron proveedores")]},proxy:true}]),model:{value:(_vm.provName),callback:function ($$v) {_vm.provName=$$v},expression:"provName"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Cliente (Opcional)","expanded":"","label-position":"inside"}},[_c('b-autocomplete',{attrs:{"placeholder":"Cliente","data":_vm.autoCompleteClient(_vm.clientName),"field":"NOMBRE_EMPRESA","autocomplete":"","icon":"credit-card-outline","open-on-focus":"","expanded":"","clearable":"","check-infinite-scroll":""},on:{"select":function (option) {
              _vm.newBankAccountMovement.CLAVE_CLIENTE = option._id;
            }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("No se encontraron proveedores")]},proxy:true}]),model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Referencia (Requerido)","expanded":"","label-position":"inside","type":{
          'is-danger': _vm.newBankAccountMovementRequiredErrors.REFERENCIA,
        },"message":{
          'El número de referencia no es valido':
            _vm.newBankAccountMovementRequiredErrors.REFERENCIA,
        }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 12345","expanded":"","icon":"account-cash","required":""},model:{value:(_vm.newBankAccountMovement.REFERENCIA),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "REFERENCIA", $$v)},expression:"newBankAccountMovement.REFERENCIA"}})],1),_c('b-field',{staticClass:"required-input",attrs:{"label":"Tipo (Requerido)","expanded":"","label-position":"inside","required":"","type":{
          'is-danger': _vm.newBankAccountMovementRequiredErrors.TIPOAFECTACION,
        },"message":{
          'El tipo no es valido':
            _vm.newBankAccountMovementRequiredErrors.TIPOAFECTACION,
        }}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Producto","expanded":"","icon":"bank-transfer"},model:{value:(_vm.newBankAccountMovement.TIPOAFECTACION),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "TIPOAFECTACION", $$v)},expression:"newBankAccountMovement.TIPOAFECTACION"}},[_c('option',{attrs:{"value":"SU"}},[_vm._v("Ingreso")]),_c('option',{attrs:{"value":"RE"}},[_vm._v("Egreso")])])],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Status (Requerido)","expanded":"","label-position":"inside","required":"","type":{ 'is-danger': _vm.newBankAccountMovementRequiredErrors.STATUS },"message":{
          'El status no es valido':
            _vm.newBankAccountMovementRequiredErrors.STATUS,
        }}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Producto","expanded":"","icon":"alpha-s-circle-outline"},model:{value:(_vm.newBankAccountMovement.STATUS),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "STATUS", $$v)},expression:"newBankAccountMovement.STATUS"}},[_c('option',{attrs:{"value":"CO"}},[_vm._v("Confirmada")]),_c('option',{attrs:{"value":"PE"}},[_vm._v("Pendiente")]),_c('option',{attrs:{"value":"CA"}},[_vm._v("Cancelada")]),_c('option',{attrs:{"value":"SC"}},[_vm._v("Sin categoría")])])],1),_c('b-field',{staticClass:"required-input",attrs:{"label":"Fecha de transferencia (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newBankAccountMovementRequiredErrors.FECHA },"message":{
          'La fecha no es valida ':
            _vm.newBankAccountMovementRequiredErrors.FECHA,
        }}},[_c('b-datepicker',{staticClass:"required-input",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"required":"","show-week-number":true,"locale":"es-MX","placeholder":"Ejemplo 20/10/2018","trap-focus":"","icon":"calendar-today","horizontal-time-picker":""},model:{value:(_vm.newBankAccountMovement.FECHA),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "FECHA", $$v)},expression:"newBankAccountMovement.FECHA"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Comercio (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 12345","expanded":"","icon":"storefront-outline"},model:{value:(_vm.newBankAccountMovement.COMERCIO),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "COMERCIO", $$v)},expression:"newBankAccountMovement.COMERCIO"}})],1),_c('b-field',{attrs:{"label":"Descripción (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 12345","expanded":"","icon":"pencil"},model:{value:(_vm.newBankAccountMovement.DESCRIPCION),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "DESCRIPCION", $$v)},expression:"newBankAccountMovement.DESCRIPCION"}})],1)],1)],1),_c('b-checkbox',{staticClass:"permissions-checkbox first-row-checkbox special-margin",attrs:{"true-value":"S","false-value":"N"},model:{value:(_vm.newBankAccountMovement.MOSTRAR_TESORERIA),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovement, "MOSTRAR_TESORERIA", $$v)},expression:"newBankAccountMovement.MOSTRAR_TESORERIA"}},[_vm._v(" Mostrar movimiento en tesoreria ")]),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveManualBankAccount()}}},[_vm._v(" Registrar movimiento bancario ")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Regresar ")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])])}]

export { render, staticRenderFns }