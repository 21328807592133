var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Sincronizar movimientos bancarios ")]),_vm._m(0),_c('div',{attrs:{"id":"belvo"}}),_c('div',{staticClass:"manual-bank-account"},[_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Fecha inicial para búsqueda (Requerido)","expanded":"","label-position":"inside","type":{
          'is-danger': _vm.newBankAccountMovementSynchRequiredErrors.FECHA_INICIO,
        },"message":{
          'La fecha de inicio no es valida ':
            _vm.newBankAccountMovementSynchRequiredErrors.FECHA_INICIO,
        }}},[_c('b-datepicker',{staticClass:"required-input",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"required":"","show-week-number":true,"locale":"es-MX","placeholder":"Ejemplo 20/10/2018","trap-focus":"","icon":"calendar-today","horizontal-time-picker":""},model:{value:(_vm.newBankAccountMovementSynch.FECHA_INICIO),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovementSynch, "FECHA_INICIO", $$v)},expression:"newBankAccountMovementSynch.FECHA_INICIO"}})],1),_c('b-field',{staticClass:"required-input",attrs:{"label":"Fecha final para búsqueda  (Requerido)","expanded":"","label-position":"inside","type":{
          'is-danger': _vm.newBankAccountMovementSynchRequiredErrors.FECHA_FIN,
        },"message":{
          'La fecha final no es valida ':
            _vm.newBankAccountMovementSynchRequiredErrors.FECHA_FIN,
        }}},[_c('b-datepicker',{staticClass:"required-input",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"required":"","show-week-number":true,"locale":"es-MX","placeholder":"Ejemplo 20/10/2018","trap-focus":"","icon":"calendar-today","horizontal-time-picker":""},model:{value:(_vm.newBankAccountMovementSynch.FECHA_FIN),callback:function ($$v) {_vm.$set(_vm.newBankAccountMovementSynch, "FECHA_FIN", $$v)},expression:"newBankAccountMovementSynch.FECHA_FIN"}})],1)],1)],1),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.connectMovements()}}},[_vm._v("Sincronizar movimiento bancario")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado")]),_vm._v(". En algunos bancos solo puede buscarse información en periodos de 6 meses o menos ")])}]

export { render, staticRenderFns }