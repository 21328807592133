/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control"></div>

      <div class="control">
        <b-button type="is-primary" @click="addBank()">Agregar banco</b-button>
      </div>
    </b-field>

    <div class="table-header">
      Cantidad de bancos: <b>{{ banks.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="banks"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Banks"
        :checked-rows.sync="checkedBanks"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="CLAVE"
      >
        <template #empty>
          <div class="has-text-centered">No hay bancos</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="CLAVE" field="CLAVE" sortable searchable>
            <span v-if="!checkIfValid(props.row.CLAVE)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CLAVE }}</span>
          </b-table-column>

          <b-table-column label="NOMBRE" field="NOMBRE" sortable searchable>
            <span v-if="!checkIfValid(props.row.NOMBRE)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NOMBRE }}</span>
          </b-table-column>

          <b-table-column label="RFC" field="RFC" sortable searchable>
            <span v-if="!checkIfValid(props.row.RFC)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.RFC }}</span>
          </b-table-column>

          <b-table-column label="CLAVESAT" field="CLAVESAT" sortable searchable>
            <span v-if="!checkIfValid(props.row.CLAVESAT)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CLAVESAT }}</span>
          </b-table-column>

          <b-table-column label="STATUS" field="STATUS" sortable searchable>
            <span v-if="!checkIfValid(props.row.STATUS)">{{
              "Sin información"
            }}</span>
            <span v-if="props.row.STATUS === 'AC'">Activa</span>
            <span v-if="props.row.STATUS === 'CA'">Cancelada</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddBankModal :bankInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddBankModal from "./AddBankModal";
import Masks from "../../../data/cleave-masks";

// @ is an alias to /src
export default {
  name: "AllBankList",
  components: {
    AddBankModal,
  },
  data() {
    return {
      perPage: 50,
      checkedBanks: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      masks: Masks,
      brandRequiredErrors: {
        CLAVE_MARC: false,
        DESC_MARC: false,
      },
    };
  },
  async created() {},
  methods: {
    async reloadInformation() {
      let allPromises = [];
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      }
      await Promise.all(allPromises);
    },
    addBank() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBankModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    banks() {
      return this.$store.getters.BANKS.map((singleBank) => {
        const bankWithProps = { ...singleBank };
        return bankWithProps;
      });
    },
    totalBalance() {
      return this.$store.getters.TOTALBALANCE;
    },
    totalBalanceCon() {
      return this.$store.getters.TOTALBALANCECON;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/GeneralResumeeBank.css";
</style>
