<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Movimientos de {{ bankAccount.NOMBRE }}
    </h1>

    <div class="invoice-div-bottom-div">
      <div class="container global-list-main-container">
        <b-tabs type="is-boxed">
          <b-tab-item label="Movimientos" icon="bank-transfer">
            <BankMovements :bankAccount="bankAccount"></BankMovements>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import BankMovements from "../components/BankMovementsList";
// @ is an alias to /src
export default {
  name: "BankMovementsComponents",
  props: ["bankAccount"],
  components: {
    BankMovements,
  },
  data() {
    return {};
  },
  methods: {},
  async created() {
    let allPromises = [];
    if (this.$store.getters.BANKACCOUNTS.length === 0) {
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
    }
    await Promise.all(allPromises);
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
