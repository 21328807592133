<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Cuentas </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Resumen" icon="cash-multiple">
        <GeneralResumee></GeneralResumee>
      </b-tab-item>
      <b-tab-item
        label="Cuentas bancarias"
        icon="bank"
        :visible="companyPaymentPlanModules.banks.getBankAccounts === 'S'"
      >
        <BankAccounts></BankAccounts>
      </b-tab-item>
      <b-tab-item label="Catálogo de bancos" icon="bank-plus">
        <AllBankList></AllBankList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import GeneralResumee from "../components/GeneralResumee";
import BankAccounts from "../components/BankAccountsList";
import AllBankList from "@/modules/Bank/components/AllBanksList";
// @ is an alias to /src
export default {
  name: "AccountsComponents",
  components: {
    AllBankList,
    GeneralResumee,
    BankAccounts,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "banks");
  },
  methods: {},
  async created() {
    let allPromises = [];
    allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
    allPromises.push(this.$store.dispatch("GETBANKS"));
    if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
    }
    await Promise.all(allPromises);
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
