/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="addBankMovement()"
          >Agregar movimientos</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            companyPaymentPlanModules.main.importBankMovements === 'S' &&
            permissions &&
            permissions.main.importBankMovements === 'S'
          "
          >Importar movimientos</b-button
        >
      </div>
      <div class="control" v-if="bankAccount.AUTOMATICO === 'S'">
        <b-button type="is-primary" @click="synchBankMovement()"
          >Sincronizar movimientos</b-button
        >
      </div>
      <div class="control">
        <b-button
          class="special-margin"
          type="is-primary"
          @click="
            startConfirmEvent(
              'success',
              function () {
                consolidateMovements(
                  bankAccount._id,
                  checkedBankAccountMovements
                );
              },
              'consolidar los movimientos'
            )
          "
          >Consolidar movimientos seleccionados</b-button
        >
      </div>

      <div class="control">
        <b-button
          class="special-margin"
          type="is-primary"
          @click="
            startConfirmEvent(
              'success',
              function () {
                changeShowAccountsMultiple(0);
              },
              'Mostrar los movimientos'
            )
          "
          >Mostar movimientos seleccionados en tesorería</b-button
        >
      </div>

      <div class="control">
        <b-button
          class="special-margin"
          type="is-primary"
          @click="
            startConfirmEvent(
              'success',
              function () {
                changeShowAccountsMultiple(1);
              },
              'ocultar los movimientos'
            )
          "
          >Ocultar movimientos seleccionados en tesorería</b-button
        >
      </div>
    </b-field>

    <div class="table-header">
      <p>
        Cantidad de movimientos bancarios: <b>{{ bankMovements.length }}</b>
      </p>
      <p v-if="bankAccount.FECHA_CONSOLIDACION">
        Ultima fecha de consolidación:
        <b>{{
          moment(bankAccount.FECHA_CONSOLIDACION).format("DD-MM-YYYY HH:mm")
        }}</b>
      </p>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="filteredBankMovements"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="bankMovementsTable"
        :checked-rows="checkedBankAccountMovements"
        checkable
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.REFERENCIA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="FECHA_FORMATO"
        :is-row-checkable="
          (row) => {
            return row.CONSOLIDADO !== 'S';
          }
        "
        @check-all="handleCheckAll"
        @check="handleCheck"
      >
        <template #empty>
          <div class="has-text-centered">No hay movimientos bancarios</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            label="Referencia"
            field="REFERENCIA"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.REFERENCIA)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.REFERENCIA }}</span>
          </b-table-column>

          <b-table-column
            label="Fecha"
            field="FECHA_FORMATO"
            sortable
            searchable
            :custom-sort="sortByDate"
          >
            <span v-if="!checkIfValid(props.row.FECHA_FORMATO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.FECHA_FORMATO }}</span>
          </b-table-column>

          <b-table-column
            label="Cliente"
            field="CLIENTE_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.CLIENTE_FOR)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.CLIENTE_FOR)">{{
              props.row.CLIENTE_FOR
            }}</span>
          </b-table-column>

          <b-table-column
            label="Proveedor"
            field="PROV_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.PROV_FOR)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.PROV_FOR)">{{
              props.row.PROV_FOR
            }}</span>
          </b-table-column>

          <b-table-column
            label="Consolidado"
            field="CONSOLIDADO_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.CONSOLIDADO_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CONSOLIDADO_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Tipo de movimiento"
            field="TIPOAFECTACION_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.TIPOAFECTACION_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.TIPOAFECTACION_FOR }}</span>
          </b-table-column>

          <b-table-column label="Importe" field="IMPORTE" sortable searchable>
            <span v-if="!checkIfValid(props.row.IMPORTE)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.IMPORTE)">{{
              props.row.IMPORTE | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column
            label="Restante a utilizar"
            field="RESTA"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.RESTA)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.RESTA)">{{
              props.row.RESTA | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column label="Status" field="STATUS_FOR" sortable searchable>
            <span v-if="!checkIfValid(props.row.STATUS_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Descripción"
            field="DESCRIPCION"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.DESCRIPCION)">{{
              "Sin información"
            }}</span>
            <span v-if="props.row.DESCRIPCION">{{
              props.row.DESCRIPCION
            }}</span>
          </b-table-column>

          <b-table-column
            label="Tipo de conexión de movimiento"
            field="AUTOMATICO_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.AUTOMATICO_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.AUTOMATICO_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Se muestra en tesorería"
            field="MOSTRAR_TESORERIA_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.MOSTRAR_TESORERIA_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.MOSTRAR_TESORERIA_FOR }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="controls">
                <p v-if="props.row.STATUS === 'CA'">
                  No hay información que mostrar
                </p>
                <b-button
                  type="is-primary"
                  v-if="props.row.STATUS !== 'CA'"
                  @click="
                    startDelete(
                      'danger',
                      function () {
                        cancelMovement(props.row);
                      },
                      'movimiento bancario',
                      'el movimiento bancario seleccionado'
                    )
                  "
                  >Cancelar movimientos bancarios</b-button
                >

                <b-button
                  type="is-primary"
                  @click="changeShowAccounts(props.row, 0)"
                  v-if="props.row.MOSTRAR_TESORERIA !== 'S'"
                  >Mostrar en tesorería</b-button
                >

                <b-button
                  type="is-primary"
                  @click="changeShowAccounts(props.row, 1)"
                  v-if="props.row.MOSTRAR_TESORERIA === 'S'"
                  >Ocultar en tesorería</b-button
                >
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddBankMovementsModal from "./AddBankMovementsModal";
import ConnectBankMovementsModal from "./ConnectBankMovementsModal";
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import ImportFileModal from "@/modules/Global/components/ImportFileModal";
import {
  openConfirmationModalSucces,
  openConfirmationModalError,
} from "@/utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "BankMovements",
  props: ["bankAccount"],
  components: {},
  directives: { cleave },
  data() {
    return {
      moment: moment,
      perPage: 50,
      checkedBankAccountMovements: [],
      checkedAll: false,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [],
      masks: Masks,
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    addBankMovement() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBankMovementsModal,
        props: {
          bankAccount: this.bankAccount,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    synchBankMovement() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ConnectBankMovementsModal,
        props: {
          bankAccount: this.bankAccount,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "bankMovements",
          nameImport: "Movimientos Bancarios",
          afterAction: "GETBANKACOUNTS",
          extraParams: { _id: this.bankAccount._id },
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc sends movements to th server to be consolidated
     */
    async consolidateMovements(bankAccountId, bankMovements) {
      try {
        if (bankMovements.length > 0) {
          let response = await this.$store.dispatch(
            "CONSOLIDATEBANKMOVEMENTS",
            { bankAccountId, bankMovements }
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se consolidó exitosamente el saldo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al consolidar el saldo",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al consolidar el saldo, seleccione uno o más movimiento para consolidar",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al consolidar el saldo",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startDelete(type, functionOnConfirm, name, deleteMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalError.call(
        this,
        type,
        functionOnConfirm,
        name,
        deleteMessage
      );
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    async cancelMovement(selectedMovement) {
      try {
        let response = await this.$store.dispatch("CANCELMOVEMENT", {
          bankAccount: this.bankAccount._id,
          movement: selectedMovement._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente el movimiento",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al cancelar el movimiento",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al cancelar el movimiento",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async changeShowAccounts(selectedMovement, type) {
      try {
        let response = await this.$store.dispatch("CHANGESHOWINACCOUNTS", {
          bankAccount: this.bankAccount._id,
          movement: selectedMovement._id,
          newValue: type === 0 ? "S" : "N",
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se actualizó exitosamente el movimiento",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al actualizar el movimiento",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al actualizar el movimiento",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async changeShowAccountsMultiple(type) {
      try {
        if (this.checkedBankAccountMovements.length > 0) {
          let response = await this.$store.dispatch(
            "CHANGESHOWINACCOUNTSMULTIPLE",
            {
              movements: this.checkedBankAccountMovements.map(
                (singleMovement) => {
                  return {
                    bankAccount: this.bankAccount._id,
                    movement: singleMovement._id,
                    newValue: type === 0 ? "S" : "N",
                  };
                }
              ),
            }
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se actualizaron exitosamente los movimientos",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al mostrar u ocultar los movimientos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al mostrar u ocultar los movimientos, seleccione uno o más movimiento",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al mostrar u ocultar los movimientos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    handleCheckAll() {
      this.checkedAll = !this.checkedAll;
      const table = this.$refs.bankMovementsTable;
      this.checkedBankAccountMovements = this.checkedAll
        ? table.$data.newData.slice(0).filter((singleMovmement) => {
            return singleMovmement.CONSOLIDADO !== "S";
          })
        : [];
    },
    handleCheck(rows, row) {
      if (row) {
        const index = this.checkedBankAccountMovements
          .map((singleMovement) => singleMovement._id)
          .indexOf(row._id);
        if (index >= 0) {
          this.checkedBankAccountMovements.splice(index, 1);
        } else {
          this.checkedBankAccountMovements.push(row);
        }
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    bankMovements() {
      const currentBankAcounts = [...this.$store.getters.BANKACCOUNTS];
      for (const singleBank of currentBankAcounts) {
        if (singleBank._id === this.bankAccount._id) {
          return singleBank.MOVIMIENTOS.map((singleBankMovement) => {
            let singleBankMovementWithProps = { ...singleBankMovement };
            singleBankMovementWithProps.CLIENTE_FOR = "";
            if (singleBankMovementWithProps.CLAVE_CLIENTE) {
              singleBankMovementWithProps.CLIENTE_FOR =
                singleBankMovementWithProps.CLAVE_CLIENTE.NOMBRE_EMPRESA;
            }
            singleBankMovementWithProps.PROV_FOR = "";
            if (singleBankMovementWithProps.CLAVE_PRV) {
              singleBankMovementWithProps.PROV_FOR =
                singleBankMovementWithProps.CLAVE_PRV.NOMBRE_PRV;
            }
            singleBankMovementWithProps.FECHA_FORMATO = moment(
              singleBankMovementWithProps.FECHA
            ).format("DD-MM-YYYY HH:mm");
            singleBankMovementWithProps.CONSOLIDADO_FOR =
              singleBankMovement.CONSOLIDADO === "S" ? "Si" : "No";
            singleBankMovementWithProps.TIPOAFECTACION_FOR =
              singleBankMovement.TIPOAFECTACION === "RE" ? "Egreso" : "Ingreso";
            singleBankMovementWithProps.IMPORTE = singleBankMovement.IMPORTE
              ? singleBankMovement.IMPORTE.toString()
              : 0;
            singleBankMovementWithProps.MOSTRAR_TESORERIA_FOR =
              singleBankMovement.MOSTRAR_TESORERIA === "S" ? "Si" : "No";
            singleBankMovementWithProps.AUTOMATICO_FOR =
              singleBankMovement.AUTOMATICO === "S" ? "Automática" : "Manual";

            singleBankMovementWithProps.STATUS_FOR = "Pendiente";
            if (
              singleBankMovement.STATUS === "CO" &&
              singleBankMovement.RESTA > 1
            ) {
              singleBankMovementWithProps.STATUS_FOR = "Confirmado";
            }
            if (
              singleBankMovement.STATUS === "PE" &&
              singleBankMovement.RESTA > 1
            ) {
              singleBankMovementWithProps.STATUS_FOR = "Pendiente";
            }
            if (
              singleBankMovement.STATUS === "CA" &&
              singleBankMovement.RESTA > 1
            ) {
              singleBankMovementWithProps.STATUS_FOR = "Cancelado";
            }
            if (
              singleBankMovement.STATUS === "CA" &&
              singleBankMovement.RESTA < 1
            ) {
              singleBankMovementWithProps.STATUS_FOR = "Aplicado";
            }

            singleBankMovementWithProps.RESTA = String(
              singleBankMovementWithProps.RESTA
            );

            return singleBankMovementWithProps;
          });
        }
      }
      return [];
    },
    filteredBankMovements() {
      return this.bankMovements.map((singleBankMovement) => {
        let singleAccountWithProps = { ...singleBankMovement };
        return singleAccountWithProps;
      });
    },
    totalBalance() {
      return this.$store.getters.TOTALBALANCE;
    },
    totalBalanceCon() {
      return this.$store.getters.TOTALBALANCECON;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/GeneralResumeeBank.css";
</style>
