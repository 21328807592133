/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar cuenta bancaria</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div class="manual-bank-account">
      <h3 class="form-division">Balance actual</h3>

      <div class="special-margin">
        <b-progress
          :rounded="false"
          type="is-success"
          size="is-medium"
          :value="newBankAccount.BALANCE_INCIAL"
          :max="newBankAccount.BALANCE_INCIAL"
          show-value
          format="raw"
          :precision="2"
          :keep-trailing-zeroes="true"
          locale="es-MX"
        >
          {{ newBankAccount.BALANCE_INCIAL | money("MXN", 2) }}
        </b-progress>
      </div>

      <h3 class="form-division">Información general</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Saldo inicial (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          :type="{ 'is-danger': newBankAccountRequiredErrors.BALANCE_INCIAL }"
          :message="{
            'El saldo inicial no es valido':
              newBankAccountRequiredErrors.BALANCE_INCIAL,
          }"
        >
          <MoneyInput
            currency="MXN"
            :digits="2"
            placeholder="Ejemplo: 123"
            icon="cash-100"
            :required="true"
            v-model="newBankAccount.BALANCE_INCIAL"
            :disabled="false"
          />
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Número de cuenta (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newBankAccountRequiredErrors.CUENTA }"
          :message="{
            'El número de cuenta no es valido':
              newBankAccountRequiredErrors.CUENTA,
          }"
        >
          <b-input
            placeholder="Ejemplo: 12345"
            type="number"
            step="any"
            expanded
            icon="account-cash"
            required
            v-model="newBankAccount.CUENTA"
          />
        </b-field>
        <b-field
          expanded
          label-position="inside"
          class="required-input"
          required
          :type="{ 'is-danger': newBankAccountRequiredErrors.BANCO }"
          :message="{
            'El banco no es valido': newBankAccountRequiredErrors.BANCO,
          }"
        >
          <template slot="label">
            Banco (Requerido)
            <b-tooltip
              multilined
              label="Si no encuentras el banco puedes agregarlo en la sección de 'Catálogo de bancos'"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-select
            placeholder="Ejemplo: Producto"
            expanded
            icon="bank"
            v-model="newBankAccount.BANCO"
          >
            <option value="" disabled>Seleccione un banco</option>
            <option
              v-for="singleBankAccount in banks"
              :value="singleBankAccount"
              :key="singleBankAccount._id"
            >
              {{ singleBankAccount.NOMBRE }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Tipo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
          :type="{ 'is-danger': newBankAccountRequiredErrors.TIPO }"
          :message="{
            'El tipo no es valido': newBankAccountRequiredErrors.TIPO,
          }"
        >
          <b-select
            placeholder="Ejemplo: Producto"
            expanded
            icon="bank-transfer"
            v-model="newBankAccount.TIPO"
          >
            <option value="CH">Cuenta de cheques</option>
            <option value="CR">Cuenta de crédito</option>
            <option value="AH">Cuenta de ahorro</option>
            <option value="PR">Cuenta de prestamo</option>
            <option value="IN">Cuenta de inversión</option>
            <option value="SC">Sin categoría</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          expanded
          label-position="inside"
          :type="{ 'is-danger': newBankAccountRequiredErrors.NOMBRE }"
          :message="{
            'El nombre de la cuenta no es valido':
              newBankAccountRequiredErrors.NOMBRE,
          }"
        >
          <template slot="label">
            Nombre de cuenta (Requerido)
            <b-tooltip label="Nombre para identificar la cuenta" multilined>
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            placeholder="Ejemplo: Cuenta principal"
            expanded
            icon="account-box"
            v-model="newBankAccount.NOMBRE"
            required
          />
        </b-field>
        <b-field label="Sucursal (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 12345"
            expanded
            icon="numeric-8-box-multiple-outline"
            v-model="newBankAccount.SUCURSAL"
          />
        </b-field>
      </b-field>

      <!--
      <b-field grouped class="special-margin">
        <b-field label="Plaza (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 12345"
            expanded
            icon="numeric-5-box-multiple-outline"
            v-model="newBankAccount.PLAZA"
          />
        </b-field>
      </b-field>
      -->

      <b-field grouped class="special-margin">
        <b-field expanded label-position="inside">
          <template slot="label">
            Nombre de identificador (Opcional)
            <b-tooltip
              multilined
              label="Nombre de algun identificador extra de la cuenta por ejemplo clabe o referencia"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            placeholder="Ejemplo: CLABE"
            expanded
            icon="numeric-6-box-multiple-outline"
            v-model="newBankAccount.NOMBRE_IDENTIFICACION"
          />
        </b-field>
        <b-field expanded label-position="inside">
          <template slot="label">
            Número de indentificador (Opcional)
            <b-tooltip
              multilined
              label="Número de algun identificador extra de la cuenta por ejemplo clabe o referencia"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            placeholder="Ejemplo: 000000000000000000"
            expanded
            icon="numeric-9-box-multiple-outline"
            v-model="newBankAccount.VALOR_IDENTIFICACION"
          />
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveManualBankAccount()">
        Registrar cuenta
      </b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button">
        Regresar
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "../../../utils/fns";

export default {
  name: "AddBankAccountModal",
  components: {},
  data() {
    return {
      moment: moment,
      newBankAccount: {
        AUTOMATICO: "N",
        NOMBRE: "",
        CUENTA: "",
        BALANCE_INCIAL: 0,
        TIPO: "CH",
        BANCO: "",
        SUCURSAL: "",
        PLAZA: "",
        NOMBRE_IDENTIFICACION: "",
        VALOR_IDENTIFICACION: "",
      },
      newBankAccountRequiredErrors: {
        NOMBRE: false,
        BALANCE_INCIAL: false,
        CUENTA: false,
        TIPO: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveManualBankAccount() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newBankAccount,
          this.newBankAccountRequiredErrors
        );
        if (checkValidationErrors(this.newBankAccountRequiredErrors)) {
          let response = await this.$store.dispatch("SAVEBANKACCOUNTS", {
            bankAccounts: [this.newBankAccount],
          });
          if (response === "Success") {
            this.deleteInformation();
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la cuenta bancaria",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la cuenta bancaria bancaria en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la cuenta bancaria en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la cuenta bancaria en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newBankAccount = {
        AUTOMATICO: "N",
        NOMBRE: "",
        CUENTA: "",
        BALANCE_INCIAL: 0,
        TIPO: "CH",
        BANCO: "",
        SUCURSAL: "",
        PLAZA: "",
        NOMBRE_IDENTIFICACION: "",
        VALOR_IDENTIFICACION: "",
        STATUS: "AC",
      };
      this.newBankAccounts = [];
      this.link = "";
      this.institution = "";
    },
  },
  computed: {
    banks() {
      return this.$store.getters.BANKS.map((singleBank) => {
        return { ...singleBank };
      }).sort((a, b) => (a.NOMBRE > b.NOMBRE ? 1 : -1));
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
