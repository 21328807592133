/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Conectar cuentas bancarias</h1>

    <div id="belvo"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkForPermissions, returnAxiosHeaders } from "../../../utils/fns";
import { serverUrl } from "../../../utils/env-variables-fns";
import axios from "axios";
import { saveAction } from "../../../utils/store-fns";
export default {
  name: "ConnectBankAccountModal",
  components: {},
  props: ["linkId"],
  data() {
    return {
      moment: moment,
      newBankAccounts: [],
      link: "",
      institution: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    this.connectBankAccount();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async createWidget() {
      if (
        checkForPermissions(
          this.$store.getters.USER.permissions.permissions,
          "belvo",
          "connecToBelvo"
        )
      ) {
        const getAccessToken = async () => {
          try {
            this.$store.commit("SET_LOADING", true);
            let response = await axios.post(
              `${serverUrl}/belvo/connect-account`,
              {
                companyMainName: this.companyMainName,
                email: this.email,
                linkId: this.linkId,
              },
              returnAxiosHeaders(this.token)
            );
            this.$store.commit("SET_LOADING", false);
            return response.data;
          } catch (error) {
            this.$store.commit("SET_LOADING", false);
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al conectar con el proveedor de cuentas bancarias",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        };
        const successCallbackFunction = async (link, institution) => {
          try {
            if (this.linkId) {
              // Update mode
              this.link = link;
              this.$store.commit("SET_LOADING", true);
              let accountsResponse = await axios.post(
                `${serverUrl}/banks/update-bank-account`,
                {
                  companyMainName: this.companyMainName,
                  email: this.email,
                  link,
                },
                returnAxiosHeaders(this.token)
              );
              this.$store.commit("UPDATE_ACCOUNT", this.linkId);
              this.$store.commit("SET_LOADING", false);
              if (accountsResponse.status === 200) {
                this.$buefy.dialog.confirm({
                  title: "Éxito",
                  message: "Se conecto exitosamente la cuenta bancaria",
                  confirmText: "Entendido",
                  type: "is-success",
                  hasIcon: true,
                  onConfirm: () => {
                    this.closeModal();
                  },
                  cancelText: "cancelar",
                  canCancel: false,
                });
              } else {
                this.$buefy.dialog.confirm({
                  title: "Error",
                  message:
                    "Hubo un error al conectar la cuenta bancaria con la base de datos",
                  confirmText: "Entendido",
                  type: "is-danger",
                  hasIcon: true,
                  cancelText: "cancelar",
                  canCancel: false,
                });
              }
            } else {
              // Save mode
              this.link = link;
              this.institution = institution;
              this.$store.commit("SET_LOADING", true);
              let accountsResponse = await axios.post(
                `${serverUrl}/belvo/get-accounts`,
                {
                  companyMainName: this.companyMainName,
                  email: this.email,
                  link,
                },
                returnAxiosHeaders(this.token)
              );
              for (const singleAccount of accountsResponse.data) {
                // If the category of account is different from this, then dont add the bank account
                if (
                  singleAccount.category !== "CHECKING_ACCOUNT" &&
                  singleAccount.category !== "INVESTMENT_ACCOUNT" &&
                  singleAccount.category !== "SAVINGS_ACCOUNT" &&
                  singleAccount.category !== "UNCATEGORIZED"
                ) {
                  continue;
                }
                const newFormattedAccount = {
                  AUTOMATICO: "S",
                  NOMBRE: singleAccount.name,
                  CUENTA: singleAccount.number,
                  BALANCE_INCIAL: singleAccount.balance.available,
                  BALANCES: singleAccount.balance,
                  NOMBRE_IDENTIFICACION:
                    singleAccount.public_identification_name,
                  VALOR_IDENTIFICACION:
                    singleAccount.public_identification_value,
                  LINK: this.link,
                  INSTITUCION: institution,
                  BANK_ID: singleAccount.id,
                };
                switch (singleAccount.category) {
                  case "CHECKING_ACCOUNT":
                    newFormattedAccount.TIPO = "CH";
                    break;
                  case "INVESTMENT_ACCOUNT":
                    newFormattedAccount.TIPO = "IN";
                    break;
                  case "SAVINGS_ACCOUNT":
                    newFormattedAccount.TIPO = "AH";
                    break;
                  case "UNCATEGORIZED":
                    newFormattedAccount.TIPO = "SC";
                    break;
                }
                this.newBankAccounts.push(newFormattedAccount);
              }
              this.$store.commit("SET_LOADING", false);
              await this.saveAutomaticBankAccounts();
            }
          } catch (error) {
            this.$store.commit("SET_LOADING", false);
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al conectar la cuenta bancaria con la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        };
        const onExitCallbackFunction = (data) => {
          this.closeModal();
        };
        const onEventCallbackFunction = (data) => {};
        const config = {
          company_name: "Coconut Control",
          company_benefit_header: "Ten mayor control de tus cuentas bancarias",
          company_benefit_content:
            "Obten el último balance de tus cuentas y todos los movimientos",
          opportunity_loss:
            "No pierdas la oportunidad de automatizar la creación de movimientos bancarios y el actualizar el saldo",
          country_codes: ["MX"],
          institution_types: ["business"],
          show_abandon_survey: false,
          locale: "es",
          callback: (link, institution) =>
            successCallbackFunction(link, institution),
          onExit: (data) => onExitCallbackFunction(),
          onEvent: (data) => onEventCallbackFunction(),
        };
        const { access } = await getAccessToken();
        window.belvoSDK.createWidget(access, config).build();
      } else {
        this.$store.commit("SET_LOADING", false);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "No cuentas con permisos suficientes para realizar esta acción",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.closeModal(),
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    loadScript(src) {
      const node = document.createElement("script");
      node.src = src;
      node.type = "text/javascript";
      node.async = true;
      node.onload = this.createWidget;
      document.body.appendChild(node);
    },
    connectBankAccount() {
      this.loadScript("https://cdn.belvo.io/belvo-widget-1-stable.js");
    },
    async saveAutomaticBankAccounts() {
      try {
        let response = await this.$store.dispatch("SAVEBANKACCOUNTS", {
          bankAccounts: this.newBankAccounts,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se conectaron exitosamente las cuentas bancarias",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al conectar las cuentas bancarias con la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al conectar las cuentas bancarias con la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    companyMainName() {
      return this.$store.getters.USER.companyMainName;
    },
    email() {
      return this.$store.getters.USER.email;
    },
    token() {
      return this.$store.getters.USER.token;
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
