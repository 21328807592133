/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar movimientos bancarios</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div id="belvo" />

    <div class="manual-bank-account">
      <h3 class="form-division">Información general</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Monto(Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          :type="{ 'is-danger': newBankAccountMovementRequiredErrors.IMPORTE }"
          :message="{
            'El importe no es valido':
              newBankAccountMovementRequiredErrors.IMPORTE,
          }"
        >
          <MoneyInput
            currency="MXN"
            :digits="2"
            placeholder="Ejemplo: 123"
            icon="cash-100"
            :required="true"
            v-model="newBankAccountMovement.IMPORTE"
            :disabled="false"
          />
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Proveedor (Opcional)"
          expanded
          label-position="inside"
          required
        >
          <b-autocomplete
            placeholder="Proveedor"
            :data="autoCompleteProviders(provName)"
            v-model="provName"
            field="NOMBRE_PRV"
            autocomplete
            icon="credit-card-outline"
            open-on-focus
            expanded
            clearable
            @select="
              (option) => {
                newBankAccountMovement.CLAVE_PRV = option._id;
              }
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron proveedores</template>
          </b-autocomplete>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Cliente (Opcional)" expanded label-position="inside">
          <b-autocomplete
            placeholder="Cliente"
            :data="autoCompleteClient(clientName)"
            v-model="clientName"
            field="NOMBRE_EMPRESA"
            autocomplete
            icon="credit-card-outline"
            open-on-focus
            expanded
            clearable
            @select="
              (option) => {
                newBankAccountMovement.CLAVE_CLIENTE = option._id;
              }
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron proveedores</template>
          </b-autocomplete>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Referencia (Requerido)"
          expanded
          label-position="inside"
          :type="{
            'is-danger': newBankAccountMovementRequiredErrors.REFERENCIA,
          }"
          :message="{
            'El número de referencia no es valido':
              newBankAccountMovementRequiredErrors.REFERENCIA,
          }"
        >
          <b-input
            placeholder="Ejemplo: 12345"
            expanded
            icon="account-cash"
            required
            v-model="newBankAccountMovement.REFERENCIA"
          />
        </b-field>
        <b-field
          label="Tipo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
          :type="{
            'is-danger': newBankAccountMovementRequiredErrors.TIPOAFECTACION,
          }"
          :message="{
            'El tipo no es valido':
              newBankAccountMovementRequiredErrors.TIPOAFECTACION,
          }"
        >
          <b-select
            placeholder="Ejemplo: Producto"
            expanded
            icon="bank-transfer"
            v-model="newBankAccountMovement.TIPOAFECTACION"
          >
            <option value="SU">Ingreso</option>
            <option value="RE">Egreso</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Status (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
          :type="{ 'is-danger': newBankAccountMovementRequiredErrors.STATUS }"
          :message="{
            'El status no es valido':
              newBankAccountMovementRequiredErrors.STATUS,
          }"
        >
          <b-select
            placeholder="Ejemplo: Producto"
            expanded
            icon="alpha-s-circle-outline"
            v-model="newBankAccountMovement.STATUS"
          >
            <option value="CO">Confirmada</option>
            <option value="PE">Pendiente</option>
            <option value="CA">Cancelada</option>
            <option value="SC">Sin categoría</option>
          </b-select>
        </b-field>
        <b-field
          label="Fecha de transferencia (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          :type="{ 'is-danger': newBankAccountMovementRequiredErrors.FECHA }"
          :message="{
            'La fecha no es valida ':
              newBankAccountMovementRequiredErrors.FECHA,
          }"
        >
          <b-datepicker
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            v-model="newBankAccountMovement.FECHA"
            required
            class="required-input"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Ejemplo 20/10/2018"
            trap-focus
            icon="calendar-today"
            horizontal-time-picker
          />
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Comercio (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 12345"
            expanded
            icon="storefront-outline"
            v-model="newBankAccountMovement.COMERCIO"
          />
        </b-field>
        <b-field
          label="Descripción (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 12345"
            expanded
            icon="pencil"
            v-model="newBankAccountMovement.DESCRIPCION"
          />
        </b-field>
      </b-field>
    </div>

    <b-checkbox
      class="permissions-checkbox first-row-checkbox special-margin"
      true-value="S"
      false-value="N"
      v-model="newBankAccountMovement.MOSTRAR_TESORERIA"
    >
      Mostrar movimiento en tesoreria
    </b-checkbox>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveManualBankAccount()">
        Registrar movimiento bancario
      </b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button">
        Regresar
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "../../../utils/fns";

export default {
  name: "AddBankMovementsModal",
  props: ["bankAccount"],
  components: {},
  data() {
    return {
      moment: moment,
      newBankAccountMovement: {
        AUTOMATICO: "N",
        FECHA: new Date(),
        REFERENCIA: "",
        TIPOAFECTACION: "SU",
        STATUS: "CO",
        IMPORTE: 0,
        DESCRIPCION: "",
        COMERCIO: "",
        CLAVE_CLIENTE: undefined,
        CLAVE_PRV: undefined,
        MOSTRAR_TESORERIA: "S",
      },
      newBankAccountMovementRequiredErrors: {
        FECHA: false,
        REFERENCIA: false,
        TIPOAFECTACION: false,
        STATUS: false,
        IMPORTE: false,
        TIPO: false,
      },
      clientName: "",
      provName: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveManualBankAccount() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newBankAccountMovement,
          this.newBankAccountMovementRequiredErrors
        );
        if (checkValidationErrors(this.newBankAccountMovementRequiredErrors)) {
          let response = await this.$store.dispatch(
            "SAVEBANKACCOUNTMOVEMENTS",
            {
              bankAccount: this.bankAccount._id,
              bankMovements: [this.newBankAccountMovement],
            }
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message:
                "Se guardó exitosamente los movimientos de la cuenta bancaria",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar los movimientos de la cuenta bancaria en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar los movimientos de la cuenta bancaria en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar los movimientos de la cuenta bancaria en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    autoCompleteClient(value) {
      if (!this.clients) {
        return [];
      }
      return this.clients.filter((singleClient) => {
        return (
          singleClient.CLAVE_CLI.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singleClient.NOMBRE_EMPRESA.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    autoCompleteProviders(value) {
      if (!this.providers) {
        return [];
      }
      return this.providers.filter((singlProvider) => {
        return (
          singlProvider.NOMBRE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singlProvider.CLAVE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
  },
  computed: {
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };

        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
