/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control"></div>

      <div class="control">
        <b-button type="is-primary" @click="addAccount()"
          >Agregar cuenta bancaria</b-button
        >
      </div>

      <div class="control">
        <b-button type="is-primary" @click="connectAccount()"
          >Conectar cuentas bancarias</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="table-header">
      Cantidad de cuentas bancarias: <b>{{ bankAccounts.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="bankAccounts"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="BankAccounts"
        :checked-rows.sync="checkedBankAccounts"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CUENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="NOMBRE"
      >
        <template #empty>
          <div class="has-text-centered">No hay cuentas bancarias</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Nombre" field="NOMBRE" sortable searchable>
            <span v-if="!checkIfValid(props.row.NOMBRE)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NOMBRE }}</span>
          </b-table-column>

          <b-table-column label="Cuenta" field="CUENTA" sortable searchable>
            <span v-if="!checkIfValid(props.row.CUENTA)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CUENTA }}</span>
          </b-table-column>

          <b-table-column
            label="Banco"
            field="BANCO.NOMBRE"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.BANCO.NOMBRE)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.BANCO.NOMBRE }}</span>
          </b-table-column>

          <b-table-column
            label="Saldo actual"
            field="SALDO_ACTUAL.SALDO_ACTUAL"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.SALDO_ACTUAL.SALDO_ACTUAL)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.SALDO_ACTUAL.SALDO_ACTUAL)">{{
              props.row.SALDO_ACTUAL.SALDO_ACTUAL | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column
            label="Saldo consolidado actual"
            field="SALDO_CON_ACTUAL.SALDO_ACTUAL"
            sortable
            searchable
          >
            <span
              v-if="!checkIfValid(props.row.SALDO_CON_ACTUAL.SALDO_ACTUAL)"
              >{{ "Sin información" }}</span
            >
            <span>{{
              props.row.SALDO_CON_ACTUAL.SALDO_ACTUAL | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column
            label="Tipo de conexión de cuenta"
            field="AUTOMATICO"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.AUTOMATICO)">{{
              "Sin información"
            }}</span>
            <span v-if="props.row.AUTOMATICO === 'S'">Automática</span>
            <span v-if="props.row.AUTOMATICO === 'N'">Manual</span>
          </b-table-column>

          <b-table-column label="Status" field="STATUS" sortable searchable>
            <span v-if="!checkIfValid(props.row.STATUS)">{{
              "Sin información"
            }}</span>
            <span v-if="props.row.STATUS === 'AC'">Activa</span>
            <span v-if="props.row.STATUS === 'CA'">Cancelada</span>
          </b-table-column>

          <b-table-column
            label="Ultima consolidación"
            field="FECHA_CONSOLIDACION"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.FECHA_CONSOLIDACION)">{{
              "Aun no se ha consolidado el saldo"
            }}</span>
            <span v-if="props.row.FECHA_CONSOLIDACION">{{
              moment(props.row.FECHA_CONSOLIDACION).format("DD-MM-YYYY HH:mm")
            }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <div class="details-controls-container">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="viewInformation(props.row)"
                    >Ver movimientos</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="
                      startConfirmEvent(
                        'success',
                        function () {
                          synchAccountBalance(props.row);
                        },
                        'Sincronizar el saldo'
                      )
                    "
                    v-if="props.row.AUTOMATICO === 'S'"
                    >Sincronizar saldo de la cuenta</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddBankAccountModal from "./AddBankAccountModal";
import ConnectBankAccountModal from "./ConnectBankAccountModal";
import BankMovementsComponents from "../views/BankMovementsComponents";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { openConfirmationModalSucces } from "../../../utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "BankAccounts",
  components: {},
  directives: { cleave },
  data() {
    return {
      moment: moment,
      perPage: 50,
      checkedBankAccounts: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      masks: Masks,
      brandRequiredErrors: {
        CLAVE_MARC: false,
        DESC_MARC: false,
      },
      refreshLoading: false,
    };
  },
  created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      }

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    addAccount() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBankAccountModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    connectAccount() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ConnectBankAccountModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    /**
     *
     * @desc handles the click of the synch account balance button, calls the needed action and displays success or error messages
     */
    async synchAccountBalance(accountInformation) {
      try {
        // Update invalid link if the account needs it
        if (accountInformation.ACTUALIZAR_LINK) {
          this.updateLink(accountInformation.LINK);
          return;
        }

        this.reloadInformation();
        let response = await this.$store.dispatch("SYNCHBANKACCOUNTBALANCES", {
          bankAccountId: accountInformation._id,
        });

        // Update invalid link if the response says it
        if (response === "Invalid link") {
          this.updateLink(accountInformation.LINK);
          return;
        }

        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se guardarón exitosamente los balances de las cuentas bancaria",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar los balances de las cuentas bancaria en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar los balances de las cuentas bancaria en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    viewInformation(account) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: BankMovementsComponents,
        props: {
          bankAccount: account,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    updateLink(bankAccountLink) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ConnectBankAccountModal,
        props: {
          linkId: bankAccountLink,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS.map((singleBankAccount) => {
        let singleBankAccountWithProps = { ...singleBankAccount };
        return singleBankAccountWithProps;
      });
    },
    totalBalance() {
      return this.$store.getters.TOTALBALANCE;
    },
    totalBalanceCon() {
      return this.$store.getters.TOTALBALANCECON;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/GeneralResumeeBank.css";
</style>
